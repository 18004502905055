import { ContainerCustom } from "@igloonet-web/shared-ui"
import React from "react"
import { Row } from "reactstrap"
import styled from "styled-components"

type Props = {
  className?: string
  children: React.ReactNode
  right?: boolean
}

const Wrapper = styled.div`
  padding: 15px;
  z-index: 1;
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 40%;
  }
`

const LeftOrRightBlock: React.FC<Props> = ({ className, children, right }) => {
  const marginClass = right ? "ml-auto" : "mr-auto"
  return (
    <ContainerCustom className={className}>
      <Row>
        <Wrapper className={marginClass}>{children}</Wrapper>
      </Row>
    </ContainerCustom>
  )
}

export default LeftOrRightBlock
