import React, { Component } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, H2, Content } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/planetaher/logo-planeta.png"
import Sira from "../../../images/reference/planetaher/sira.png"
import Screen from "../../../images/reference/planetaher/ph.png"
import Sideimg from "../../../images/reference/planetaher/screen-2.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import LeftOrRightBlock from "../../../components/reference-detail/left-or-right-block"

class PlanetaHer extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Reference na nový e-shop Planeta her | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="PLANETA HER"
          heading="Reference na nový e-shop Planeta her"
          date="Q3 2015 - Q1 2016"
        />

        <ReferenceImageText
          right
          img={Screen}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Planeta her"
          link="//planetaher.cz/"
        >
          <p>
            Planetaher.cz je specializovaný obchod s moderními společenskými
            hrami, který vznikl v roce 2007. Jejich e‑shop obsahuje jeden z
            největších výběrů her v České republice. Kromě běžně dostupných her
            je v obchodě možno zakoupit především hry, které si získaly
            obrovskou oblibu v zahraničí a na českém trhu nejsou běžně k
            dostání. Roku 2011 pak tým rozšířil své aktivity a založil vlastní
            vydavatelství deskových her - REXhry, které se zabývá vydáváním
            zahraničních her pro začátečníky i náročné hráče.
          </p>
        </ReferenceImageText>

        <LeftOrRightBlock right>
          <H2>Zadání</H2>
          <Content>
            <ul>
              <li>
                Navrhnout novou vizuální podobu e‑shopu a zajistit jeho
                kompletní realizaci.
              </li>
              <li>
                Vytvořit vlastní funkce a nahradit nevhodný ekonomický software.
              </li>
              <li>Exportovat doklady do účetního programu.</li>
            </ul>
          </Content>
        </LeftOrRightBlock>

        <LeftOrRightBlock>
          <H2>Rozsah projektu</H2>
          <p>
            S internetovým obchodem Planetaher.cz jsme v oblasti{" "}
            <Link to="/marketing/reference/planetaher">online marketingu</Link>{" "}
            začali spolupracovat již v listopadu 2013. Tehdy jsme převzali
            stávající PPC kampaně v AdWords a Sklik. Naše dlouholetá spolupráce
            vyvrcholila v roce 2016 vývojem nového e‑shopu.
          </p>
          <p>
            Díky blízké spolupráci s klientem jsme dokázali vyřešit také jeho
            dílčí problémy a úspěšně jsme zprovoznili vlastní pokladní systém,
            který umožňuje správu objednávek přes nový e‑shop.
          </p>
        </LeftOrRightBlock>

        <ReferenceImageText halfImgSize img={Sideimg} alt="">
          <H2>Historie spolupráce</H2>
          <ul>
            <li>Listopad 2013 - opravení stávajících kampaní ve vyhledávání</li>
            <li>
              Prosinec 2013 - spuštění remarketingu s textovými reklamami, PLA
              kampaně a předvánoční kampaně na Facebooku
            </li>
            <li>
              2014 až současnost - kompletní správa kampaní AdWords a Sklik,
              remarketing na Facebooku, správa zbožových srovnávačů
            </li>
            <li>2015 - počátek prací na vývoji nového webu</li>
            <li>2016 - zveřejnění nového e‑shopu</li>
          </ul>
        </ReferenceImageText>

        <PaddingWrapper>
          <Medailon
            personName="Michal Široký"
            position="spolumajitel"
            img={Sira}
          >
            <p>igloonet je pro nás super partner jak lidsky, tak pracovně.</p>
          </Medailon>
        </PaddingWrapper>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default PlanetaHer
